import clearViewGrains from "./clearview-grains";
import rkm from "./rkm";
import sharedon from "./sharedon";
import crookedCreekAcres from "./crookedCreekAcres";
import bacresGrain from "./bacres-grain";
import ganaraskaGrain from "./ganaraska-grain";
import stLawrenceGrain from "./st-lawrence-grain";
import lockie from "./lockie";
import talbot from "./talbot";
import rosendale from './rosendale-farms'
import westlockTerminals from "./westlock-terminals";
import arkwood from "./arkwood-mills";
import scienceHill from "./scienceHill";
import coxFarms from "./coxFarms";
import rockyPond from "./rockyPond";
import addisGrain from "./addisGrain";
import maxwellGrain from "./maxwellGrain";
import demoElevator from "./demoElevator";

const config = {
  clearViewGrains,
  rkm,
  sharedon,
  crookedCreekAcres,
  bacresGrain,
  ganaraskaGrain,
  stLawrenceGrain,
  lockie,
  talbot,
  rosendale,
  westlockTerminals,
  arkwood,
  scienceHill,
  coxFarms,
  rockyPond,
  addisGrain,
  maxwellGrain,
  demoElevator
};

const company = process.env.REACT_APP_COMPANY_NAME;

export default config[company];
